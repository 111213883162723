<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-modal
    id="warehouse-modal-event-handler"
    ref="warehouse-modal-event-handler"
    v-model="show"
    ok-only
    ok-title="Crea"
    centered
    size="md"
    no-close-on-backdrop
    :title="titleEventHandler"
    @ok.prevent="handleOk"
  >
    <!-- {{ magazzino }} -->
    <validation-observer ref="validation-observer-reparto-modal-event-handler">
      <b-row no-gutters>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="nome magazzino"
            rules="required"
          >
            <b-form-group
              label="Nome"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="magazzino.nome"
                  placeholder="nome magazzino"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="codice"
            rules="required"
          >
            <b-form-group
              label="Codice"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="magazzino.codice"
                  placeholder="cod magazzino"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="tipo"
            rules="required"
          >
            <b-form-group
              label="Tipo"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="magazzino.tipo"
                  placeholder="tipo magazzino"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="dimensione"
            rules="required"
          >
            <b-form-group
              label="Dimensione"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="magazzino.dimensione"
                  placeholder="dimensione"
                  type="number"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="indirizzo"
            rules="required"
          >
            <b-form-group
              label="Indirizzo"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="magazzino.indirizzo"
                  placeholder="indirizzo"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="'Punti Vendita Associati'"
            label-class="font-weight-bolder"
          >
            <validation-provider
              #default="{ errors }"
              name="Prod. Associato"
              rules="required"
            >
              <v-select
                v-model="magazzino.puntiVendita"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="nome"
                :clearable="false"
                value="id"
                multiple
                :reduce="(val) => ({ id: val.id })"
                filterable
                placeholder="punti vendita associati"
                :options="puntiVenditaOption"
                @open="getPuntiVenditaOption"
                @input="limiterSelection"
              >
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #no-options="{ search, searching, loading }">
                  <div>Ricerca Punti Vendita...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #loading="{ search, searching, loading }">
                  <div>Caricamento Punti Vendita...</div>
                </template>
                <!-- eslint-disable-next-line vue/no-unused-vars  -->
                <template #searching="{ search, searching, loading }">
                  <div>Sto cercando...</div>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="descrizione"
            rules="required"
          >
            <b-form-group
              label="Descrizione"
              label-class="font-weight-bolder"
            >
              <b-input-group>
                <b-form-input
                  v-model="magazzino.descrizione"
                  placeholder="descrizione"
                />
              </b-input-group>
              <small class="text-danger mt-25">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <ejs-toast
        id="toast_type"
        ref="toastRef"
        :position="{ X: 'Right' }"
      />
    </validation-observer>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import {
  BFormGroup, BInputGroup, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import { getPuntiVendita } from '@/@core/api-service/logistic/puntiVendita'
import { setMagazzini } from '@/@core/api-service/logistic/warehouses'
// import axios from 'axios'
Vue.use(ToastPlugin)

export default {
  components: {
    BRow, BCol, BFormGroup, BInputGroup, BFormInput, ValidationProvider, ValidationObserver, vSelect,
  },
  data() {
    return {
      show: false,
      titleEventHandler: '',
      magazzino: {
        id: 0,
        nome: '',
        codice: '',
        descrizione: '',
        tipo: '',
        dimensione: 0,
        indirizzo: '',
        puntiVendita: [],
        escludiPuntiVendita: 'N'
      },
      required,
      locale: 'it',
      repartiOption: [],
      puntiVenditaOption: [],
    }
  },
  mounted() {
    localize(this.locale)
  },
  computed: {
    repartiPadreOption() {
        // this.$store.getters['logistic/getRecapiti']
        return this.$store.getters['logistic/getReparti']
        // return this.repartiOption
    },
  },
  methods: {
    getPuntiVenditaOption() {
      getPuntiVendita().then(res => {
        if(res.data.esito === 'OK') {
          this.puntiVenditaOption = [...res.data.puntivendita]
        }
      })
    },
    handleOk() {
      this.setMagazzini()
    },
    setMagazzino(magazzino) {
      this.magazzino = { ...magazzino }
    },
    openModal() {
      this.show = true
    },
    setTitle(title) {
      this.titleEventHandler = title
    },
    limiterSelection(e) {
      if(e.length > 1) {
        e.pop()
      }
    },
    async setMagazzini() {
      if (!Object.prototype.hasOwnProperty.call(this.magazzino, 'puntiVendita') ) {
        this.magazzino.puntiVendita = []
      }
      const magazzini = JSON.stringify([{...this.magazzino, escludiPuntiVendita: 'N' }]) //puntiVendita: [{ id: 1}]
      // console.log('magazzini', magazzini)
      setMagazzini(magazzini)
        .then(res => {
        console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('ok')
          const magazzini = [...res.data.magazzini.map(el => ({
            id: el.id,
            nome: el.nome,
            codice: el.codice,
            descrizione: el.descrizione,
            tipo: el.tipo,
            dimensione: el.dimensione,
            indirizzo: el.indirizzo,
            puntiVendita: [...el.puntiVendita]
          }))]
          // console.log('magazzini', magazzini)
          this.$emit('update-magazzini', magazzini)
          this.resetMagazzino()
          this.$nextTick(() => {
            this.$refs['warehouse-modal-event-handler'].toggle('#toggle-btn')
          })
        } else if (res.data.esito === 'KO') {
          this.$refs.toastRef.show({
            title: 'Errore Inserimento', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
          })
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories)
    },
    resetMagazzino() {
      this.magazzino = {
        id: 0,
        nome: '',
        codice: '',
        descrizione: '',
        tipo: '',
        dimensione: 0,
        indirizzo: '',
        puntiVendita: [],
        escludiPuntiVendita: 'N'
      }
    },
  },
}
</script>

<style>

</style>
