var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"warehouse-modal-event-handler",attrs:{"id":"warehouse-modal-event-handler","ok-only":"","ok-title":"Crea","centered":"","size":"md","no-close-on-backdrop":"","title":_vm.titleEventHandler},on:{"ok":function($event){$event.preventDefault();return _vm.handleOk($event)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('validation-observer',{ref:"validation-observer-reparto-modal-event-handler"},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"nome magazzino","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"nome magazzino"},model:{value:(_vm.magazzino.nome),callback:function ($$v) {_vm.$set(_vm.magazzino, "nome", $$v)},expression:"magazzino.nome"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"codice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Codice","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"cod magazzino"},model:{value:(_vm.magazzino.codice),callback:function ($$v) {_vm.$set(_vm.magazzino, "codice", $$v)},expression:"magazzino.codice"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"tipo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"tipo magazzino"},model:{value:(_vm.magazzino.tipo),callback:function ($$v) {_vm.$set(_vm.magazzino, "tipo", $$v)},expression:"magazzino.tipo"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"dimensione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dimensione","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"dimensione","type":"number"},model:{value:(_vm.magazzino.dimensione),callback:function ($$v) {_vm.$set(_vm.magazzino, "dimensione", $$v)},expression:"magazzino.dimensione"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"indirizzo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Indirizzo","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"indirizzo"},model:{value:(_vm.magazzino.indirizzo),callback:function ($$v) {_vm.$set(_vm.magazzino, "indirizzo", $$v)},expression:"magazzino.indirizzo"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":'Punti Vendita Associati',"label-class":"font-weight-bolder"}},[_c('validation-provider',{attrs:{"name":"Prod. Associato","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"nome","clearable":false,"value":"id","multiple":"","reduce":function (val) { return ({ id: val.id }); },"filterable":"","placeholder":"punti vendita associati","options":_vm.puntiVenditaOption},on:{"open":_vm.getPuntiVenditaOption,"input":_vm.limiterSelection},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Ricerca Punti Vendita...")])]}},{key:"loading",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Caricamento Punti Vendita...")])]}},{key:"searching",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_c('div',[_vm._v("Sto cercando...")])]}}],null,true),model:{value:(_vm.magazzino.puntiVendita),callback:function ($$v) {_vm.$set(_vm.magazzino, "puntiVendita", $$v)},expression:"magazzino.puntiVendita"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"descrizione","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descrizione","label-class":"font-weight-bolder"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"descrizione"},model:{value:(_vm.magazzino.descrizione),callback:function ($$v) {_vm.$set(_vm.magazzino, "descrizione", $$v)},expression:"magazzino.descrizione"}})],1),_c('small',{staticClass:"text-danger mt-25"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('ejs-toast',{ref:"toastRef",attrs:{"id":"toast_type","position":{ X: 'Right' }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }